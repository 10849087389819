import { call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  createContact,
  getContact,
  getContactFailure,
  getContactSuccess,
  getSingleContact,
  getSingleContactFailure,
  getSingleContactSuccess,
} from "./ContactSlice";

function* onCreateContact({
  payload: {
    data,
    options: { resetForm, setSubmitting },
  },
}) {
  try {
    const url = `/contact-us/contact-us-create`;
    const response = yield call(post, url, data);

    if (response) {
      toaster("success", `Message send successfully`);
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Message send fail`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetContacts({ payload: { limit = 0, page = 0 } }) {
  try {
    const url = `/contact-us/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getContactSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get contacts failed";
    yield put(getContactFailure({ message }));
  }
}

function* onGetSingleContact({ payload: { id = "" } }) {
  try {
    const url = `/contact-us/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleContactSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get contact data failed";
    yield put(getSingleContactFailure({ message }));
  }
}

export function* contactSaga() {
  yield takeEvery(createContact.type, onCreateContact);
  yield takeEvery(getContact.type, onGetContacts);
  yield takeEvery(getSingleContact.type, onGetSingleContact);
}
