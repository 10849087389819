import { all, fork } from "redux-saga/effects";
import { adminDashboardSaga } from "./Admin/AdminDashboard/saga";
import { adminNotificationSaga } from "./Admin/AdminNotification/saga";
import { applicantSaga } from "./Admin/Applicant/saga";
import { careerBlogSaga } from "./Admin/CareerAdvice/Blog/saga";
import { careerCategoriesSaga } from "./Admin/CareerAdvice/Categories/saga";
import { bannerSectionSaga } from "./Admin/CareerCounseling/BannerSection/saga";
import { careerServicesSaga } from "./Admin/CareerCounseling/Services/saga";
import { commonDataSaga } from "./Admin/CommonData/saga";
import { cvScoringSaga } from "./Admin/CvScoring/saga";
import { islamGroupSaga } from "./Admin/IslamGroup/saga";
import { islamGroupDashboardSaga } from "./Admin/IslamGroupDashboard/saga";
import { meetingScheduleSaga } from "./Admin/Mentor/MeetingSchedule/saga";
import { mentorListSaga } from "./Admin/Mentor/MentorList/saga";
import { timeSlotSaga } from "./Admin/Mentor/TimeSlot/saga";
import { passageSaga } from "./Admin/Passage/saga";
import { salesListSaga } from "./Admin/ServicePurchases/Sales/saga";
import { testimonialSaga } from "./Admin/Testimonial/saga";
import { applicationNotificationSaga } from "./ApplicantPanel/ApplicationNotification/saga";
import { scrollingDataSaga } from "./ApplicantPanel/CVScrollingData/saga";
import { checkoutPaymentSaga } from "./ApplicantPanel/Checkout/saga";
import { contactSaga } from "./ApplicantPanel/Contact/saga";
import { profileUserSaga } from "./ApplicantPanel/Profile/saga";
import { purchasesServicesSaga } from "./ApplicantPanel/PurchasesService/saga";
import { loginSaga } from "./Auth/Login/saga";
import { employeeNotificationSaga } from "./EmployeePanel/EnployeeNotification/saga";
import { addMeetingScheduleSaga } from "./EmployeePanel/MeetingSchedule/AddMeetingSchedule/saga";
import { meetingScheduleMentorSaga } from "./EmployeePanel/MeetingSchedule/saga";
import { mentorDashboardSaga } from "./EmployeePanel/MentorDashboard/saga";
import { taskListSaga } from "./EmployeePanel/TaskList/saga";
import { userProfileSaga } from "./EmployeePanel/UserProfile/saga";
import LayoutSaga from "./Layout/saga";
import { userSaga } from "./User/saga";

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(LayoutSaga),
    fork(userSaga),
    fork(passageSaga),
    fork(userProfileSaga),
    fork(adminDashboardSaga),
    fork(careerCategoriesSaga),
    fork(careerBlogSaga),
    fork(careerServicesSaga),
    fork(timeSlotSaga),
    fork(meetingScheduleSaga),
    fork(testimonialSaga),
    fork(bannerSectionSaga),
    fork(mentorListSaga),
    fork(commonDataSaga),
    fork(profileUserSaga),
    fork(purchasesServicesSaga),
    fork(cvScoringSaga),
    fork(applicantSaga),
    fork(adminNotificationSaga),
    // user
    fork(checkoutPaymentSaga),
    fork(salesListSaga),
    fork(scrollingDataSaga),
    fork(applicationNotificationSaga),
    // mentor
    fork(taskListSaga),
    fork(meetingScheduleMentorSaga),
    fork(mentorDashboardSaga),
    fork(addMeetingScheduleSaga),
    fork(employeeNotificationSaga),
    fork(islamGroupSaga),
    fork(islamGroupDashboardSaga),
    fork(contactSaga),
  ]);
}
