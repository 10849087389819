import adminDashboardSlice from "./Admin/AdminDashboard/adminDashboardSlice";
import AdminNotificationSlice from "./Admin/AdminNotification/AdminNotificationSlice";
import ApplicantSlice from "./Admin/Applicant/ApplicantSlice";
import CareerBlogSlice from "./Admin/CareerAdvice/Blog/CareerBlogSlice";
import CareerCategoriesSlice from "./Admin/CareerAdvice/Categories/CareerCategoriesSlice";
import BannerSectionSlice from "./Admin/CareerCounseling/BannerSection/BannerSectionSlice";
import CareerServicesSlice from "./Admin/CareerCounseling/Services/CareerServicesSlice";
import CommonDataSlice from "./Admin/CommonData/CommonDataSlice";
import CvScoringSlice from "./Admin/CvScoring/CvScoringSlice";
import islamGroupSlice from "./Admin/IslamGroup/islamGroupSlice";
import islamGroupDashboardSlice from "./Admin/IslamGroupDashboard/islamGroupDashboardSlice";
import MeetingScheduleSlice from "./Admin/Mentor/MeetingSchedule/MeetingScheduleSlice";
import MentorListSlice from "./Admin/Mentor/MentorList/MentorListSlice";
import timeSlotSlice from "./Admin/Mentor/TimeSlot/TimeSlotSlice";
import PassageSlice from "./Admin/Passage/PassageSlice";
import salesListSlice from "./Admin/ServicePurchases/Sales/SalesDetailsSlice";
import TestimonialSlice from "./Admin/Testimonial/TestimonialSlice";
import applicantNotificationSlice from "./ApplicantPanel/ApplicationNotification/ApplicationNotificationSlice";
import scrollingDataSlice from "./ApplicantPanel/CVScrollingData/CVScrollingDataSlice";
import CheckoutSlice from "./ApplicantPanel/Checkout/CheckoutSlice";
import ContactSlice from "./ApplicantPanel/Contact/ContactSlice";
import ProfileUserSlice from "./ApplicantPanel/Profile/ProfileUserSlice";
import PurchasesServiceSlice from "./ApplicantPanel/PurchasesService/PurchasesServiceSlice";
import loginSlice from "./Auth/Login/loginSlice";
import EmployeeNotificationSlice from "./EmployeePanel/EnployeeNotification/EmployeeNotificationSlice";
import AddMeetingScheduleSlice from "./EmployeePanel/MeetingSchedule/AddMeetingSchedule/AddMeetingScheduleSlice";
import MeetingScheduleMentorSlice from "./EmployeePanel/MeetingSchedule/MeetingScheduleSlice";
import mentorDashboardSlice from "./EmployeePanel/MentorDashboard/mentorDashboardSlice";
import TaskListSlice from "./EmployeePanel/TaskList/TaskListSlice";
import userProfileSlice from "./EmployeePanel/UserProfile/userProfileSlice";
import filterSlice from "./Filter/filterSlice";
import LayoutSlice from "./Layout/LayoutSlice";
import UserSlice from "./User/UserSlice";
import cvScrollingSlice from "./web/Home/CVScrolling/cvScrollingSlice";

export const rootReducers = {
  login: loginSlice,
  layout: LayoutSlice,
  user: UserSlice,
  filter: filterSlice,
  passage: PassageSlice,
  profileUser: ProfileUserSlice,
  mentorProfile: userProfileSlice,
  adminDashboard: adminDashboardSlice,
  cvScrolling: cvScrollingSlice,
  careerCategories: CareerCategoriesSlice,
  careerBlog: CareerBlogSlice,
  careerServices: CareerServicesSlice,
  timeSlot: timeSlotSlice,
  meetingSchedule: MeetingScheduleSlice,
  testimonial: TestimonialSlice,
  bannerSection: BannerSectionSlice,
  mentorList: MentorListSlice,
  commonData: CommonDataSlice,
  cvScoring: CvScoringSlice,
  applicant: ApplicantSlice,
  adminNotification: AdminNotificationSlice,
  // user
  checkoutPayment: CheckoutSlice,
  purchasesService: PurchasesServiceSlice,
  salesList: salesListSlice,
  scrollingData: scrollingDataSlice,
  applicantNotification: applicantNotificationSlice,
  contact: ContactSlice,
  // mentor
  taskList: TaskListSlice,
  meetingScheduleMentor: MeetingScheduleMentorSlice,
  mentorDashboard: mentorDashboardSlice,
  addMeetingSchedule: AddMeetingScheduleSlice,
  employeeNotification: EmployeeNotificationSlice,
  islamGroup: islamGroupSlice,
  islamGroupDashboard: islamGroupDashboardSlice,
};
