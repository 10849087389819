import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  contactData: {},
  singleContactData: {},
  error: "",
};

const contactSlice = createSlice({
  name: "contact",
  initialState: initialState,
  reducers: {
    getContact: (state, action) => {
      state.loading = true;
    },
    getContactSuccess: (state, action) => {
      state.contactData = action.payload;
      state.loading = false;
    },
    getContactFailure: (state, action) => {
      state.error = action.payload.message;
      state.contactData = {};
      state.loading = false;
    },
    getSingleContact: (state, action) => {
      state.loading = true;
    },
    getSingleContactSuccess: (state, action) => {
      state.singleContactData = action.payload;
      state.loading = false;
    },
    getSingleContactFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    createContact: (state, action) => {},
  },
});

export const {
  createContact,
  getContact,
  getContactSuccess,
  getContactFailure,
  getSingleContact,
  getSingleContactSuccess,
  getSingleContactFailure,
} = contactSlice.actions;

export default contactSlice.reducer;
